






















































































import Vue from "vue";
import api from "@/core/utils/api";
import { Action, Getter } from "vuex-class";
import { Employee, SelectItem } from "@/core/models";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class SingleEmployee extends Vue {
  @Prop({ default: () => false }) isOwner!: boolean;
  @Prop({ default: () => false }) canManage!: boolean;
  @Prop({ default: () => ({} as Employee) }) employee!: Employee;
  @Getter("company/id") companyId!: number;
  @Getter("profile/getUserEmail") currentUserEmail!: string;
  @Action("company/removeUser") removeUser!: Function;
  @Action("company/updateRole") updateRoleAction!: Function;
  @Action("displaySnackbar") displaySnackbar!: (m: string) => void;

  updateDialog = false;
  removeDialog = false;
  updating = false;
  removing = false;
  get isCurrentUser() {
    return this.employee.Email === this.currentUserEmail;
  }

  get role() {
    const { IsOwner, IsManager } = this.employee;
    let role = this.$t("roles.employee").toString();
    if (IsOwner) role = this.$t("roles.owner").toString();
    else if (IsManager) role = this.$t("roles.manager").toString();
    return role;
  }

  get displayName() {
    const { Email, Username } = this.employee;
    const name = `${Username} (${Email}) - ${this.role}`;
    return name;
  }

  newRole = "";
  get roles(): SelectItem[] {
    return [
      {
        text: this.$t("roles.employee").toString(),
        disabled: !this.employee.IsManager,
        value: "employee",
      },
      {
        text: this.$t("roles.manager").toString(),
        disabled: this.employee.IsManager,
        value: "manager",
      },
    ];
  }
  async updateRole() {
    this.updating = true;
    try {
      const isManager = this.newRole === "manager";
      const end = "/api/Company/ChangeUserRole";
      const data = {
        isManager,
        companyId: this.companyId,
        userEmail: this.employee.Email,
      };
      await api.put(end, data);
      this.updateRoleAction(data);
    } catch (error) {
      console.log(error);
    }
    this.updating = false;
    this.updateDialog = false;
  }
  async remove() {
    this.removing = true;
    try {
      const email = this.employee.Email;
      const end_base = "/api/Company/RemoveUser";
      const end = `${end_base}/${this.companyId}/${email}`;
      await api.delete(end);
      this.removeUser(email);
    } catch (error) {
      console.log(error);
      this.displaySnackbar("Could not remove user");
    }
    this.removing = false;
    this.removeDialog = false;
  }
}
