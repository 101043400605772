import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"company"}},[(!_vm.getting)?_c('div',[(_vm.hasCompany)?_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,[_c('div',{staticClass:"text-h4 font-weight-bold",staticStyle:{"font-size":"30px"}},[_vm._v(" "+_vm._s(_vm.company.Name)+" ")]),_c('div',[_vm._v(_vm._s(_vm._f("format")(_vm.company.CreatedAt)))]),(!_vm.isActive)?_c('div',[_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.$t("company.inactive")))]),(_vm.isOwner)?_c('div',[_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.$t("company.pdesc"))+" ")]),_c(VBtn,{attrs:{"link":"","to":"/company/pricing","color":"primary"}},[_c('span',[_vm._v(_vm._s(_vm.$t("company.plans")))])])],1):_vm._e()]):_vm._e()]),(_vm.isOwner)?_c(VCol,{staticClass:"d-flex",attrs:{"cols":"12"}},[_c(VSpacer),_c('EditCompanyName'),_c('div',{staticClass:"mx-2"}),_c('Upgrade',{attrs:{"company-prop":_vm.company,"disabled":!_vm.isActive},on:{"update:companyProp":function($event){_vm.company=$event},"update:company-prop":function($event){_vm.company=$event}}})],1):_vm._e(),_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{attrs:{"elevation":"0"}},[_c(VCardTitle,{staticClass:"d-flex pb-0"},[_c('span',[_vm._v(_vm._s(_vm.$t("company.emp")))]),_c(VSpacer),(_vm.isOwner)?_c('AddEmployees',{attrs:{"id":_vm.company.ID}}):_vm._e()],1),(_vm.company.Employees && _vm.company.Employees.length !== 0)?_c(VCardText,{staticClass:"pt-0"},[_c(VList,{staticClass:"mt-4",attrs:{"color":"transparent"}},_vm._l((_vm.company.Employees),function(e,i){return _c('SingleEmployee',{key:i,attrs:{"employee":e,"is-owner":_vm.isOwner,"can-manage":_vm.canManage(e)}})}),1)],1):_c(VCardText,[_vm._v(_vm._s(_vm.$t("company.noemp")))])],1)],1)],1)],1):_c(VContainer,[_c('span',[_vm._v(_vm._s(_vm.$t("company.none")))])])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }