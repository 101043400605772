

































































import Vue from "vue";
import { Getter } from "vuex-class";
import { Company, Employee } from "@/core/models";
import Upgrade from "../components/Upgrade.vue";
import AddEmployees from "../components/AddEmployees.vue";
import { Component, Watch } from "vue-property-decorator";
import CreateCompany from "../components/CreateCompany.vue";
import SingleEmployee from "../components/SingleEmployee.vue";
import EditCompanyName from "../components/EditCompanyName.vue";

@Component({
  components: {
    CreateCompany,
    EditCompanyName,
    Upgrade,
    AddEmployees,
    SingleEmployee,
  },
})
export default class CompanyDashboard extends Vue {
  @Getter("company/getting") getting!: boolean;
  @Getter("company/company") company!: Company;
  @Getter("company/isActive") isActive!: boolean;
  @Getter("company/hasCompany") hasCompany!: boolean;
  @Getter("profile/getUserEmail") currentUserEmail!: string;

  get isOwner() {
    if (!this.company.Owner) return false;
    return this.company.Owner.Email === this.currentUserEmail;
  }
  canManage(e: Employee) {
    if (this.isOwner) return !e.IsOwner;
    const isManager = this.company.Employees.find(
      x => x.Email === this.currentUserEmail,
    )?.IsManager;
    return isManager && (!e.IsManager || !e.IsOwner);
  }

  @Watch("hasCompany")
  redirect() {
    const homePath = "/company";
    if (!this.hasCompany) this.$router.push(homePath);
  }

  @Watch("isActive")
  activeChange() {
    if (this.isActive) this.$router.push("/dashboard");
  }
}
