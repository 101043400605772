





































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";

type NewUser = {
  userEmail: string;
  isManager: boolean;
  invalid: boolean;
};
const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/;

@Component
export default class AddEmployees extends Vue {
  @Prop({ default: () => 0 }) id!: number;
  @Prop({ default: () => false }) disabled!: boolean;
  @Getter("company/adding") loading!: boolean;
  @Action("company/inviteUsers") inviteUsers!: Function;
  @Action("displaySnackbar") displaySnackbar!: Function;

  dialog = false;
  addLoading = false;
  users: NewUser[] = [{ userEmail: "", isManager: false, invalid: false }];
  append() {
    this.users.push({ userEmail: "", isManager: false, invalid: false });
  }
  remove(item: NewUser) {
    const idx = this.users.indexOf(item);
    if (idx === -1) return;
    this.users.splice(idx, 1);
  }
  addCancel() {
    this.users = [{ userEmail: "", isManager: false, invalid: false }];
    this.dialog = false;
  }
  addConfirm() {
    if (this.loading) return;
    if (!this.isValid) {
      const msg = this.$t("snack.users.emails").toString();
      this.displaySnackbar(msg);
      return;
    }

    const data = {
      companyId: this.id,
      employeeData: this.users,
    };
    this.inviteUsers(data);
    this.users = [{ userEmail: "", isManager: false, invalid: false }];
    this.dialog = false;
  }

  get isValid() {
    let valid = true;
    this.users.forEach(u => {
      if (regex.test(u.userEmail)) u.invalid = false;
      else {
        u.invalid = true;
        valid = false;
      }
    });
    return valid;
  }
}
